import React, { useEffect } from "react";
import { Stack, Box, Container, Typography } from "@mui/material";
import { ReactComponent as Appstore } from "../../assets/svgs/app_store_2.svg";
import { ReactComponent as Playstore } from "../../assets/svgs/google_play_2.svg";
import { ReactComponent as Star1 } from "../../assets/svgs/star1.svg";
import { ReactComponent as Star2 } from "../../assets/svgs/star2.svg";
import { ReactComponent as Star3 } from "../../assets/svgs/star3.svg";
import FashionBanner from "./FashionBanner";
import Services from "./Services";
import StitchInStepsFlow from "./Works";
import WhyChooseStitchIn from "./WhyChoose";
import FAQs from "./FAQ";
import CurvedBanner from "./Curved";
import { useNavigate } from "react-router";

function Home() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const navigate = useNavigate();
  return (
    <>
      <Container sx={{ mt: "80px", position: "relative" }} maxWidth="xl">
        <Star3 />
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <h1 className="text-align-center fw-600 fs-45">INDIA’S FIRST</h1>
          <Star1 style={{ marginTop: "-20px" }} />
        </Stack>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          gap={2}
        >
          <Box
            sx={{
              width: { xs: "100px", sm: "200px" },
              height: { xs: "45px", sm: "70px" },
              bgcolor: "#E5AFFB",
              borderRadius: "40px",
            }}
          >
            <Typography
              className="text-align-center fw-600 black"
              sx={{ fontSize: { xs: "30px", sm: "45px" } }}
            >
              Quick
            </Typography>
          </Box>
          <Typography
            className="text-align-center fw-600"
            sx={{ fontSize: { xs: "30px", sm: "45px" } }}
          >
            Stitching App
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <h2 className="fw-400 lh-35 m-0 text-align-center">
            Stitching, Alterations & Laundry — On Demand. <br />
            Empowering Every Urban Household.
          </h2>
          <Star2 style={{ marginTop: "-20px" }} />
        </Stack>
        <br />
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          gap={2}
          sx={{
            zIndex: -1,
            mt: 2,
          }}
        >
          <Playstore
            className="cursor"
            sx={{
              zIndex: 1,
            }}
            onClick={() => {
              navigate("/coming_soon");
            }}
          />
          <Appstore
            className="cursor"
            onClick={() => {
              navigate("/coming_soon");
            }}
          />
        </Stack>
        <br />
      </Container>
      <CurvedBanner />
      <br />
      <br />
      <FashionBanner />
      <br />
      <Services id="services" />
      <br />
      <br />
      <StitchInStepsFlow id="how-it-works" />
      <br />
      <WhyChooseStitchIn id="why-us" />
      <br />
      <Container id="faqs" maxWidth="xl" className="scroll-target">
        <FAQs />
      </Container>
    </>
  );
}

export default Home;
