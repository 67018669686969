import React from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import { Container } from "@mui/system";
import DeliveryPerson from "../../assets/calling_bell.png";
import { ReactComponent as Global } from "../../assets/svgs/global.svg";
import { ReactComponent as QualityIcon } from "../../assets/svgs/quality_icon.svg";

export default function WhyChooseStitchIn({ id }) {
  return (
    <Box
      id={id}
      sx={{ flexGrow: 1, backgroundColor: "#ffffff", py: 6, px: 2 }}
      className="scroll-target"
    >
      <Typography
        variant="h4"
        align="center"
        sx={{ fontWeight: 600, mb: 4, fontSize: { xs: "30px" } }}
      >
        Why Choose StitchIn?
      </Typography>
      <Container maxWidth="xl">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid size={{ xs: 12 }}>
            <Card
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                borderRadius: 3,
                bgcolor: "#E5AFFB",
                m: "auto",
                boxShadow: "none",
              }}
            >
              <CardMedia
                component="img"
                image={DeliveryPerson}
                alt="Delivery Woman"
                sx={{
                  width: { xs: "100%", sm: "40%" },
                  height: { xs: 200, sm: 250 },
                  objectFit: "cover",
                  borderRadius: 2,
                }}
              />
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  px: 3,
                  pt: { xs: 2, sm: 0 },
                }}
              >
                <Global style={{ color: "#1496A6", fontSize: 36 }} />
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 500,
                    color: "#151515",
                    fontSize: { xs: "25px", sm: "25px", md: "35px" },
                  }}
                >
                  Driven by Women Experts in Measurement
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            size={{ xs: 12, sm: 12, md: 3 }}
            sx={{
              backgroundColor: "#FA803D",
              borderRadius: 3,
              height: "200px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "none",
              color: "#ffffff",
            }}
          >
            <Typography
              variant="h6"
              className="fw-400"
              sx={{
                fontSize: { xs: "25px", sm: "25px", md: "35px" },
              }}
            >
              100% Dedication
            </Typography>
            <Typography
              className="fw-400"
              sx={{
                fontSize: { xs: "20px", sm: "20px", md: "20px", lg: "26px" },
              }}
            >
              Verified and Trusted Experts
            </Typography>
          </Grid>
          <Grid
            size={{ xs: 12, sm: 12, md: 3 }}
            sx={{
              backgroundColor: "#F6F9F8",
              borderRadius: 3,
              height: "200px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "none",
              gap: 2,
            }}
          >
            <QualityIcon style={{ color: "#1496A6" }} />
            <Typography
              className="fw-400"
              sx={{
                fontSize: {
                  xs: "20px",
                  sm: "20px",
                  md: "20px",
                  lg: "26px",
                },
              }}
            >
              Quick & Quality service
            </Typography>
          </Grid>
          <Grid
            size={{ xs: 12, sm: 12, md: 3 }}
            sx={{
              backgroundColor: "#DDC6E7",
              borderRadius: 3,
              height: "200px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "none",
              color: "black",
            }}
          >
            <Typography
              variant="h6"
              className="fw-400 text-aling-center fs-26 "
            >
              Freedom to Cancel <br />
              or Reschedule
            </Typography>
          </Grid>
          <Grid
            size={{ xs: 12, sm: 12, md: 3 }}
            sx={{
              backgroundColor: "#FFC751",
              borderRadius: 3,
              color: "#5D5555",
              height: "200px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography className="fs-26 fw-400">
              Your Order <br />
              Delivered within <br /> 24 hrs
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
    // <Box sx={{ backgroundColor: "#83D7CA", py: 6, px: 2 }}>
    //   <Typography variant="h4" align="center" sx={{ fontWeight: 600, mb: 4 }}>
    //     Why Choose StitchIn?
    //   </Typography>
    //   <Grid container display="flex" justifyContent="center">
    //     <Grid size={{ xs: 12 }}>
    //       <Card
    //         sx={{
    //           width: { xs: "100%", md: "80%" },
    //           display: "flex",
    //           flexDirection: { xs: "column", sm: "row" },
    //           borderRadius: 3,
    //           p: 2,
    //           boxShadow: 3,
    //           backgroundColor: "#FDF1F1",
    //           m: "auto",
    //         }}
    //       >
    //         <CardMedia
    //           component="img"
    //           image={DeliveryPerson}
    //           alt="Delivery Woman"
    //           sx={{
    //             width: { xs: "100%", sm: "40%" },
    //             height: { xs: 200, sm: 250 },
    //             objectFit: "cover",
    //             borderRadius: 2,
    //           }}
    //         />
    //         <CardContent
    //           sx={{
    //             display: "flex",
    //             alignItems: "center",
    //             gap: 2,
    //             px: 3,
    //             pt: { xs: 2, sm: 0 },
    //           }}
    //         >
    //           <Global style={{ color: "#1496A6", fontSize: 36 }} />
    //           <Typography
    //             variant="h6"
    //             sx={{ fontWeight: 500, color: "#151515" }}
    //           >
    //             Powered By Strong Women Workforce
    //           </Typography>
    //         </CardContent>
    //       </Card>
    //     </Grid>
    //     <Grid size={{ xs: 4, sm: 12 }}>
    //       <Card
    //         sx={{
    //           backgroundColor: "#F8C8DC",
    //           borderRadius: 3,
    //           height: "100%",
    //           width: "100%",
    //           display: "flex",
    //           flexDirection: "column",
    //           justifyContent: "center",
    //           p: 3,
    //         }}
    //       >
    //         <Typography variant="h6" sx={{ fontWeight: 600 }}>
    //           100% Dedication
    //         </Typography>
    //         <Typography variant="body1">Trusted By 100+ Families</Typography>
    //       </Card>
    //     </Grid>
    //     <Grid size={{ xs: 4, sm: 12 }}>
    //       <Card
    //         sx={{
    //           backgroundColor: "#F5F9F8",
    //           borderRadius: 3,
    //           width: "100%",
    //           height: "100%",
    //           display: "flex",
    //           flexDirection: "column",
    //           justifyContent: "center",
    //           p: 3,
    //         }}
    //       >
    //         <Box display="flex" alignItems="center" gap={1} mb={1}>
    //           <Global style={{ color: "#1496A6" }} />
    //           <Typography variant="h6" sx={{ fontWeight: 600 }}>
    //             Quick & Quality Service
    //           </Typography>
    //         </Box>
    //       </Card>
    //     </Grid>
    //     <Grid size={{ xs: 4, sm: 12 }}>
    //       <Card
    //         sx={{
    //           backgroundColor: "#4697A5",
    //           color: "#fff",
    //           borderRadius: 3,
    //           height: "100%",
    //           width: "100%",
    //           display: "flex",
    //           flexDirection: "column",
    //           justifyContent: "center",
    //           alignItems: "center",
    //           p: 3,
    //           textAlign: "center",
    //         }}
    //       >
    //         <Typography variant="h6" sx={{ fontWeight: 600 }}>
    //           Your Order Delivered Within 24 Hrs
    //         </Typography>
    //       </Card>
    //     </Grid>
    //   </Grid>
    // </Box>
  );
}
