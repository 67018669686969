import React from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  IconButton,
  Stack,
  FormHelperText,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as Star2 } from "../../assets/svgs/star2.svg";
import { ReactComponent as Star3 } from "../../assets/svgs/star3.svg";
import { ReactComponent as Appstore } from "../../assets/svgs/app_store.svg";
import { ReactComponent as Playstore } from "../../assets/svgs/play_store.svg";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import { socialIcons } from "../../common/socialIcons";
import { useNavigate } from "react-router";

const Footer = (props) => {
  const { handleSubmit, register, errors } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  return (
    <Box sx={{ backgroundColor: "#F3C86E", color: "#5D5555", px: 4, py: 6 }}>
      <Star3 />
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
      >
        <h1
          className="text-align-center fw-600 fs-45 "
          style={{ fontStyle: "italic" }}
        >
          INDIA’S FIRST
        </h1>
      </Stack>
      <Stack
        direction={isMobile ? "column" : "row"}
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        gap={2}
      >
        <Box
          sx={{
            width: { xs: "100px", sm: "200px" },
            height: { xs: "45px", sm: "70px" },
            bgcolor: "#FA803D",
            borderRadius: "40px",
          }}
        >
          <Typography
            className="text-align-center fw-600 white"
            sx={{ fontSize: { xs: "30px", sm: "45px" } }}
          >
            Quick
          </Typography>
        </Box>
        <Typography className="text-align-center fw-600 fs-45">
          Stitching App
        </Typography>
      </Stack>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
      >
        <h2 className="fw-400 lh-35 m-0 text-align-center">
          Stitching, Alterations & Laundry — On Demand. <br />
          Empowering Every Urban Household.
        </h2>
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        alignContent="center"
      >
        <Star2 style={{ marginTop: "-20px" }} />
      </Stack>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        gap={2}
      >
        <Playstore
          className="cursor"
          onClick={() => {
            navigate("/coming_soon");
          }}
        />
        <Appstore
          className="cursor"
          onClick={() => {
            navigate("/coming_soon");
          }}
        />
      </Stack>
      <br />
      <br />
      <h2 className=" fs-25 text-align-center fw-400">
        Subscribe for our newsletters
      </h2>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          bgcolor: "#F3C86E",
          borderRadius: "20px",
          border: "0.5px solid #5D5555",
          overflow: "hidden",
          p: 0.5,
          width: { xs: "300px", sm: "500px" },
          margin: "auto",
        }}
      >
        <TextField
          variant="standard"
          placeholder="youremail@gmail.com"
          name="email"
          {...register("email", {
            required: "Please enter email address",
          })}
          slotProps={{
            input: {
              sx: {
                pl: 2,
                py: 1,
                borderColor: "#FFC751",
                border: "none",
                flex: 1,
                backgroundColor: "FFC751", // default
                "&:-webkit-autofill": {
                  boxShadow: "0 0 0 1000px #FFC751 inset !important",
                  WebkitTextFillColor: "black",
                },
              },
            },
          }}
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            flexGrow: 1,
            bgcolor: "transparent",
            "& .MuiInputBase-root": {
              bgcolor: "transparent",
            },
            "& .MuiInput-underline:before": {
              borderBottom: "none",
            },
            "& .MuiInput-underline:after": {
              borderBottom: "none",
            },
            "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
              borderBottom: "none",
            },
          }}
        />

        <SubdirectoryArrowRightIcon
          sx={{
            color: "#34343C",
            cursor: "pointer",
          }}
          onClick={handleSubmit}
        />
      </Box>

      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
      >
        {errors?.email && (
          <FormHelperText sx={{ mt: 1 }}>
            {errors?.email?.message}
          </FormHelperText>
        )}
      </Stack>

      <br />
      <br />
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} md={4}>
          <Box display="flex" gap={2} justifyContent="center" mx="auto">
            {socialIcons.map((item, index) => (
              <IconButton
                key={item.id}
                href={item.href}
                target="_blank"
                sx={{
                  color: "black", // Default color
                  transition: "color 0.3s ease-in-out", // Smooth color transition
                  "&:hover": {
                    color: "#FA803D", // Red color on hover
                  },
                }}
              >
                {item.icon}
              </IconButton>
            ))}
          </Box>
        </Grid>
      </Grid>
      <Typography
        variant="h1"
        textAlign="center"
        sx={{
          opacity: 0.3,
          fontSize: { xs: 90, sm: 200, md: 250, lg: 300 },
          fontWeight: 700,
          color: "#5D5555",
        }}
      >
        StitchIn
      </Typography>
      <Typography
        variant="body2"
        sx={{
          mx: "auto",
          pt: 2,
          opacity: 1,
        }}
        textAlign="center"
      >
        StitchIn &#169; Copyright 2025, All Rights Reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
