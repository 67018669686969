import React, { useState, useEffect } from "react";
import {
  Box,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  LinearProgress,
} from "@mui/material";
import NearBy from "../../assets/near_by_you.png";
import Recommended from "../../assets/recommended.png";
import Measurement from "../../assets/measurement.png";
import Ontheway from "../../assets/on_the_way.png";
import Delivered from "../../assets/delivered.png";

const steps = [
  {
    title: "Near by you",
    description:
      "Tell us where you are—we’ll show you top nearby boutiques to choose from.",
    bg: "#FA803D",
    label: "STEP 1",
    image: NearBy,
    color: "#ffffff",
    des_color: "#ffffff",
    id: 1,
  },
  {
    title: "Your way / Recommended",
    description:
      "Pick a design from our boutique collection or send us your favorite style — we’ll stitch it just for you!",
    bg: "#E6CEF0",
    label: "STEP 2",
    image: Recommended,
    color: "#292929",
    des_color: "#292929",
    id: 2,
  },
  {
    title: "Measurement",
    description:
      "We measure, collect, and deliver your fabric to your preferred boutique — right from your doorstep.",
    bg: "#FFC751",
    label: "STEP 3",
    image: Measurement,
    color: "#5C5F5F",
    des_color: "#5C5F5F",
    id: 3,
  },
  {
    title: "On the way",
    description: "Stitched and ready? We’ll bring it for you!",
    bg: "#F39765",
    label: "STEP 4",
    image: Ontheway,
    color: "#5C5F5F",
    des_color: "#5C5F5F",
    id: 4,
  },
  {
    title: "Delivery",
    description: "Stitched & Delivered. On Time, Every Time.",
    bg: "#E5AFFB",
    label: "STEP 5",
    image: Delivered,
    color: "#000000",
    des_color: "#000000",
    id: 5,
  },
];

const StepCard = ({ step, isMain, onClick, progress }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const getCardStyles = () => ({
    backgroundColor: step.bg,
    p: 2,
    borderRadius: 3,
    display: "flex",
    flexDirection: isMain ? (isMobile ? "column" : "row") : "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: getMinHeight(),
    width: getCardWidth(),
    transition: "all 0.3s ease-in-out",
    cursor: isMain ? "default" : "pointer",
    m: isMobile && "auto",
    mb: isMobile ? 2 : 0,
  });

  const getMinHeight = () => {
    if (isMain) return isMobile ? 300 : 350;
    return isMobile ? 80 : 250;
  };

  const getCardWidth = () => {
    if (isMobile) return "85%";
    return isMain ? "70%" : "5%";
  };

  const renderMainCard = () => (
    <Stack
      direction={isMobile ? "column" : "row"}
      alignItems="center"
      spacing={isMobile ? 2 : 4}
    >
      <Box
        component="img"
        src={step.image}
        alt={step.title}
        sx={{
          width: isMobile ? "100%" : "40%",
          height: "auto",
          borderRadius: 2,
        }}
      />
      <Box textAlign={isMobile ? "center" : "left"}>
        <Typography variant="caption" sx={{ color: step.color }}>
          {step.label}
        </Typography>
        <Typography
          variant="h5"
          fontWeight={600}
          sx={{ color: step.color }}
          fontSize={isMobile ? 16 : 45}
          mt={1}
        >
          {step.title}
        </Typography>
        <Typography
          mt={1}
          fontSize={isMobile ? 16 : 35}
          fontWeight={400}
          sx={{ color: step.des_color }}
        >
          {step.description}
        </Typography>
        <br />
        <br />
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{
            mt: 2,
            height: 6,
            borderRadius: 5,
            backgroundColor: "#ffffff55", // track color
            "& .MuiLinearProgress-bar": {
              backgroundColor: step.des_color, // custom bar color
            },
          }}
        />
      </Box>
    </Stack>
  );

  const renderCollapsedCard = () => {
    const verticalStyles = !isMobile
      ? {
          writingMode: "vertical-rl",
          transform: "rotate(180deg)",
        }
      : {};

    return (
      <Stack
        direction="column"
        justifyContent={isMobile ? "center" : "flex-end"}
        alignItems="center"
        height="100%"
        gap={isMobile ? 2 : 10}
      >
        <Typography
          sx={{
            ...verticalStyles,
            textAlign: "center",
            color: step.color,
            fontSize: isMobile ? 16 : 25,
          }}
          mt={1}
        >
          {step.title}
        </Typography>
        <Typography
          fontWeight="bold"
          sx={{
            ...verticalStyles,
            textAlign: "center",
            color: step.color,
          }}
        >
          {step.label}
        </Typography>
      </Stack>
    );
  };

  return (
    <Box onClick={onClick} sx={getCardStyles()}>
      {isMain ? renderMainCard() : renderCollapsedCard()}
    </Box>
  );
};

export default function StitchInStepsFlow({ id }) {
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let progressVal = 0;
    const interval = setInterval(() => {
      progressVal += 2;
      setProgress(progressVal);
      if (progressVal >= 100) {
        setActiveStep((prev) => (prev + 1) % steps.length);
        setProgress(0);
      }
    }, 100); // 100 * 50 = 5s

    return () => clearInterval(interval);
  }, [activeStep]);

  return (
    <Box id={id} className="scroll-target">
      <Typography
        textAlign="center"
        variant={isMobile ? "h5" : "h4"}
        mb={isMobile ? 2 : 4}
        fontWeight={600}
      >
        How StitchIn Works?
      </Typography>

      {isMobile ? (
        <Box display="flex" flexDirection="column" width="100%">
          {/* Previous Steps */}
          {steps.slice(0, activeStep).map((step, index) => (
            <StepCard
              key={step.id}
              step={step}
              isMain={false}
              onClick={() => setActiveStep(index)}
              progress={progress}
            />
          ))}

          {/* Active Step */}
          <StepCard
            step={steps[activeStep]}
            isMain={true}
            onClick={() => {}}
            progress={progress}
          />

          {/* Remaining Steps */}
          {steps.slice(activeStep + 1).map((step, index) => (
            <StepCard
              key={step.id}
              step={step}
              isMain={false}
              onClick={() => setActiveStep(index + activeStep + 1)}
              progress={progress}
            />
          ))}
        </Box>
      ) : (
        <Box
          display="flex"
          alignItems="stretch"
          justifyContent="flex-start"
          overflow="auto"
          sx={{ gap: 2, width: "100%", flexWrap: "nowrap" }}
        >
          {/* Previous steps on left */}
          {steps.slice(0, activeStep).map((step, index) => (
            <StepCard
              key={step.id}
              step={step}
              isMain={false}
              onClick={() => setActiveStep(index)}
              progress={progress}
            />
          ))}

          {/* Main step */}
          <StepCard
            step={steps[activeStep]}
            isMain={true}
            onClick={() => {}}
            progress={progress}
          />

          {/* Next steps on right */}
          {steps.slice(activeStep + 1).map((step, index) => (
            <StepCard
              key={step.id}
              step={step}
              isMain={false}
              onClick={() => setActiveStep(index + activeStep + 1)}
              progress={progress}
            />
          ))}
        </Box>
      )}
    </Box>
  );
}
